const appConfig = {
  apiPrefix: "/api",
  authenticatedEntryPath: "/app/admin/dashboard",
  unAuthenticatedEntryPath: "/admin/sign-in",
  tourPath: "/admin/sign-in",
  enableMock: true,
  pagePerData: 10,
  configurationPagePerData:5,
};

export default appConfig;
